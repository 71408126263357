$globalFont: 'Signika', sans-serif;
$globalLineHeight: 1.167;
$globalFontSizeBig: 2.0625rem;

$background-color: #ffffff;
$foreground-color: rgba(77, 201, 242, 0.4);
$accent: #ff5c00;
$accentSecondary: #383838;
$accentSecondaryLight: rgba(56, 56, 56, 0.8);
$accentLight: rgba(255, 92, 0, 0.7);
$accentLightest: rgba(255, 92, 0, 0.1);

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$label: rgba(34, 51, 84, 0.7);
$label-focused: rgb(0, 171, 85);
$label-error: rgb(255, 72, 66);

$input-error-text: rgb(255, 72, 66);
$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: rgb(0, 171, 85);
$input-border-error: rgb(255, 72, 66);

$primary-button-background: rgb(0, 171, 85);
$primary-button-background-hover: rgb(0, 123, 85);
$primary-button-shadow: rgba(0, 171, 85, 0.24) 0 6px 16px 0;

$secondary-button-background: #ff5c00;
$secondary-button-background-hover: #a73e02;
$secondary-button-shadow: rgba(255, 92, 0, 0.24) 0 6px 16px 0;
