@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.layout {
  flex: 1 1 0;
  height: 100%;

  .withSidebar {
    position: relative;
    z-index: 5;
    display: block;
    flex: 1 1 0;

    @include bp(l) {
      padding-top: 80px;
      margin-left: 290px;
    }

    .content {
      display: block;
    }
  }
}
