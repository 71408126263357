@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.sidebarContainer {
  display: inline-block;
  width: 290px;
  color: rgba(255, 255, 255, 0.9);
  z-index: 7;
  height: 100%;
  padding-bottom: 61px;
  position: fixed;
  left: 0;
  top: 0;
  background: $accentSecondary;
  box-shadow: none;

  .sidebarContent {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .mainContent {
      position: absolute;
      inset: 0;
      overflow: scroll;
      margin-right: -15px;
      margin-bottom: -15px;

      .logoContainer {
        margin-top: 27px;

        .logo {
          margin-left: 18px;
          margin-right: 18px;
          width: calc(290px - 36px);

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .sidebarDivider {
        flex-shrink: 0;
        border: 0;
        height: 1px;
        margin: 27px 18px;
        background: rgba(255, 255, 255, 0.3);
      }

      .menuItemsContainer {
        display: block;

        .menuItems {
          list-style: none;
          margin: 0;
          position: relative;
          padding: 9px;

          .menuItemsTitle {
            box-sizing: border-box;
            list-style: none;
            font-family: $globalFont;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.75rem;
            color: rgba(255, 255, 255, 0.7);
            padding: 0 22.5px;
            line-height: 1.4;
          }

          .menuSubItemsContainer {
            display: block;

            .menuSubItems {
              padding: 9px;
              list-style: none;
              margin: 0;
              position: relative;

              .subItemContainer {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                text-decoration: none;
                width: 100%;
                box-sizing: border-box;
                text-align: left;
                padding: 1px 0;

                .subItem {
                  display: flex;
                  color: rgba(255, 255, 255, 0.7);
                  background-color: transparent;
                  width: 100%;
                  justify-content: flex-start;
                  padding: 10.8px 27px;
                  align-items: center;
                  position: relative;
                  box-sizing: border-box;
                  outline: 0;
                  border: 0;
                  margin: 0;
                  cursor: pointer;
                  user-select: none;
                  vertical-align: middle;
                  appearance: none;
                  text-decoration: none;
                  font-family: $globalFont;
                  font-size: 0.875rem;
                  line-height: 1.75;
                  min-width: 64px;
                  border-radius: 10px;
                  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                  font-weight: bold;
                  text-transform: none;

                  &.activeSubItem {
                    background-color: rgba(255, 255, 255, 0.06);
                    color: rgb(255, 255, 255);
                  }

                  .navigationIcon {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .bottomScrollContainer {
      position: absolute;
      height: 6px;
      transition: opacity 200ms ease 0s;
      opacity: 0;
      right: 2px;
      bottom: 2px;
      left: 2px;
      border-radius: 3px;

      .bottomScroll {
        position: relative;
        display: block;
        height: 100%;
        cursor: pointer;
        border-radius: inherit;
        background-color: rgba(0, 0, 0, 0.2);
        width: 0;
      }
    }

    .rightScrollContainer {
      position: absolute;
      width: 6px;
      transition: opacity 200ms ease 0s;
      opacity: 0;
      right: 2px;
      bottom: 2px;
      top: 2px;
      border-radius: 3px;

      .rightScroll {
        width: 5px;
        background: rgba(34, 51, 84, 0.1);
        border-radius: 12px;
        transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
  }
}
