@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.navigationContainer {
  display: flex;
  align-items: center;
  height: 80px;
  color: $accent;
  padding-left: 18px;
  padding-right: 18px;
  right: 0;
  z-index: 6;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(3px);
  justify-content: space-between;
  box-shadow: rgba(34, 51, 84, 0.2) 0 2px 8px -3px, rgba(34, 51, 84, 0.1) 0px 5px 22px -4px;
  width: calc(100% - 36px);

  @include bp(l) {
    left: 290px;
    width: auto;
    padding: 0 18px;
    position: fixed;
  }

  .iconItems {
    display: flex;
    align-items: center;

    button {
      color: $accent;
    }
  }
}

.mobileDrawer {
  top: 80px;
  position: absolute;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 6;
  box-shadow: rgba(34, 51, 84, 0.2) 0 2px 8px -3px, rgba(34, 51, 84, 0.1) 0px 5px 22px -4px;

  .navigationItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    width: 100%;

    a {
      font-family: $globalFont;
      font-weight: 600;
      font-size: 18px;
      line-height: 40px;
      color: $accent;
      text-decoration: none;
    }
  }

  .activeNavigationItem {
    background-color: $accentLightest;
  }
}
